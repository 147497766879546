import type { Urls, DeliveryEntityData } from "core/typings";
import { EntityFirestore } from "core/models";
import { DOMAIN_NAME } from "shared/config";

import { query, where, or } from "firebase/firestore";
import { hasObjectChanged } from "shared/helpers";
import store from "global/store";

export class DeliveryEntity extends EntityFirestore<DeliveryEntityData> {
	static readonly collPath = "deliveries";
	static readonly usesSubscription = true;
	static readonly usesBundle = false;

	client: string;
	number: string;
	imageUrls: Urls;
	manager: string;
	services: number;
	prepaidExpense: number;
	payment: number;
	balanceResidue: number;
	feasibilityStudy: number;
	shipping: number;
	storageFee: number;
	otherFee: number;
	purchaseAmount: number;
	surcharge: number;
	renovationCity: string;
	date: Date;
	status: "" | "delivered" | "in stock" | "in transit" | "cleared";
	dateOfDelivery: Date;
	warehouseDate: Date;
	container: string;
	model: string;
	vin: string;
	auction: string;
	portLoading: string;
	portDischarge: string;
	lot: string;
	key: boolean;
	title: boolean;

	createDate: Date;

	get imageUrl() {
		return this.imageUrls.length > 0
			? this.imageUrls[0]
			: `https://${DOMAIN_NAME}/assets/icons/no_image.jpg`;
	}

	constructor(data?: DeliveryEntityData) {
		super(data);

		if (data) {
			this.client = data.client;
			this.number = data.number;
			this.imageUrls = data.imageUrls;
			this.manager = data.manager;

			this.services = data.services;
			this.prepaidExpense = data.prepaidExpense;
			this.payment = data.payment;
			this.balanceResidue = data.balanceResidue;
			this.feasibilityStudy = data.feasibilityStudy;
			this.shipping = data.shipping;
			this.storageFee = data.storageFee;
			this.otherFee = data.otherFee;
			this.purchaseAmount = data.purchaseAmount;
			this.surcharge = data.surcharge;

			this.renovationCity = data.renovationCity;
			this.date = new Date(data.date);

			this.status = data.status;
			this.dateOfDelivery = data.dateOfDelivery
				? new Date(data.dateOfDelivery)
				: null;
			this.warehouseDate = data.warehouseDate
				? new Date(data.warehouseDate)
				: null;
			this.container = data.container;

			this.model = data.model;

			this.vin = data.vin;
			this.auction = data.auction;
			this.portLoading = data.portLoading;
			this.portDischarge = data.portDischarge;
			this.lot = data.lot;
			this.key = data.key;
			this.title = data.title;

			this.createDate = new Date(data.createDate);
		} else {
			this.client = "";
			this.number = "";
			this.imageUrls = [];
			this.manager = "";

			this.services = 0;
			this.prepaidExpense = 0;
			this.payment = 0;
			this.balanceResidue = 0;
			this.feasibilityStudy = 0;
			this.shipping = 0;
			this.storageFee = 0;
			this.otherFee = 0;
			this.purchaseAmount = 0;
			this.surcharge = 0;

			this.renovationCity = "";
			this.date = new Date();

			this.status = "";
			this.dateOfDelivery = null;
			this.warehouseDate = null;
			this.container = "";

			this.model = "";

			this.vin = "";
			this.auction = "";
			this.portLoading = "";
			this.portDischarge = "";
			this.lot = "";
			this.key = false;
			this.title = false;

			this.createDate = new Date();
		}
	}

	static getMyDeliveries(deliveries: DeliveryEntity[]) {
		if (!deliveries || !store.user) {
			return [];
		}
		switch (store.user.role) {
			case "admin": {
				return [];
			}
			case "manager":
			case "managerPlus": {
				return deliveries.filter(
					(delivery) => delivery.manager === store.user.name,
				);
			}
			case "client": {
				return deliveries;
			}
		}
	}

	static isMyDelivery(delivery: DeliveryEntity) {
		if (!store.user) {
			return false;
		}
		switch (store.user.role) {
			case "admin": {
				return false;
			}
			case "manager":
			case "managerPlus": {
				return delivery.manager === store.user.name;
			}
			case "client": {
				return delivery.client === store.user.name;
			}
		}
	}

	static get cachedCollRef() {
		if (store.user === null) {
			return null;
		}

		let deliveriesQuery = query(this.collRef);

		switch (store.user.role) {
			case "admin": {
				break;
			}
			case "manager":
			case "managerPlus": {
				if (store.user.createdUsers.length > 0) {
					deliveriesQuery = query(
						deliveriesQuery,
						or(
							where("manager", "==", store.user.name),
							where("client", "in", store.user.createdUsers),
						),
					);
					break;
				}

				deliveriesQuery = query(
					deliveriesQuery,

					where("manager", "==", store.user.name),
				);
				break;
			}
			case "client": {
				deliveriesQuery = query(
					deliveriesQuery,
					where("client", "==", store.user.name),
				);

				break;
			}
		}

		return deliveriesQuery;
	}

	static cachedItemsUpdate() {
		if (
			store.deliveries.length === 0 ||
			hasObjectChanged(this.cachedItems, store.deliveries)
		) {
			store.deliveries = this.cachedItems.map((item) => item.clone());
		}
	}

	static sortCachedItems() {
		this.cachedItems.sort((a, b) => {
			if (a.createDate < b.createDate) {
				return 1;
			}
			return -1;
		});
	}

	static convertTimestampsFromFirestore(data) {
		super.convertTimestampsFromFirestore(data);

		if (data.dateOfDelivery) {
			data.dateOfDelivery = data.dateOfDelivery.toDate();
		} else {
			data.dateOfDelivery = null;
		}

		if (data.warehouseDate) {
			data.warehouseDate = data.warehouseDate.toDate();
		} else {
			data.warehouseDate = null;
		}

		data.date = data.date.toDate();
		data.createDate = data.createDate.toDate();
	}
}
